




























































import { Component } from 'vue-property-decorator'

import { Heading, HeadingLevel, Picker } from '../../../../contracts'

import { FormFieldset } from '../../../atoms'
import { HeadingForm, LinkForm, SetForm } from '../../../molecules'

import { AbstractModuleForm } from '../../_abstract'

import { jobOffersContentFactory } from '../JobOffers.factory'
import {
  JobOffersModule,
  JobOffersModuleContent
} from '../JobOffers.contracts'

/**
 * TODO: Reformat code.
 *
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl> (original)
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl> (edited)
 */
@Component({
  name: 'JobOffersModuleForm',
  components: {
    FormFieldset,
    HeadingForm,
    LinkForm,
    SetForm
  }
})
export class JobOffersModuleForm extends AbstractModuleForm<JobOffersModule> {
  /**
   * @inheritDoc
   */
  protected initialContent: JobOffersModuleContent = jobOffersContentFactory()

  /**
   * Instance of picker enum.
   */
  public picker = Picker

  /**
   * Checks whether heading is present
   */
  public get hasHeader (): boolean {
    return typeof this._content.heading !== 'undefined' && !!this._content.heading
  }

  public set hasHeader (value: boolean) {
    if (!value) {
      this._content.heading = undefined
      return
    }

    this._content.heading = {
      level: HeadingLevel.H5,
      text: ''
    }
  }

  /**
   * Determines whether module has header.
   */
  public get hasReadMoreLink (): boolean {
    return typeof this._content.readMoreLink !== 'undefined' && !!this._content.readMoreLink
  }

  public set hasReadMoreLink (value: boolean) {
    if (!value) {
      this._content.readMoreLink = null
      return
    }

    this._content.readMoreLink = {
      label: '',
      target: ''
    }
  }

  /**
   * Fires when heading updates.
   * @param heading
   */
  public onHeadingUpdate (heading: Heading): void {
    this._content = {
      ...this._content,
      heading: heading
    }
  }
}

export default JobOffersModuleForm
